<template>
  <div>
    <b-row>
      <b-col v-if="helpSupportData">
        <b-card-actions
          :title="helpSupportData.question"
          action-collapse
        >
          <!-- eslint-disable vue/no-v-html -->
          <div class="ql-snow">
            <div
              class="ql-editor"
              v-html="helpSupportData.answer"
            />
          </div>
        </b-card-actions>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import { BRow, BCol } from 'bootstrap-vue'
import { ref, onUnmounted } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import listModule from '@/navigation/vertical'

import helpSupportStoreModule from '../helpSupportStoreModule'

export default {
  components: {
    BRow,
    BCol,
    BCardActions,
  },
  setup() {
    const helpSupportData = ref(null)

    const HELP_SUPPORT_STORE_MODULE_NAME = 'landing-help-support'

    // Register module
    if (!store.hasModule(HELP_SUPPORT_STORE_MODULE_NAME)) {
      store.registerModule(
        HELP_SUPPORT_STORE_MODULE_NAME,
        helpSupportStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(HELP_SUPPORT_STORE_MODULE_NAME)) { store.unregisterModule(HELP_SUPPORT_STORE_MODULE_NAME) }
    })

    store
      .dispatch('landing-help-support/fetchQuestion', {
        slug: router.currentRoute.params.slug,
      })
      .then(response => {
        helpSupportData.value = response.data.data
      })
      .catch(error => {
        if (error.response.status === 404) {
          helpSupportData.value = undefined
        }
      })

    return {
      helpSupportData,
      listModule,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
</style>
